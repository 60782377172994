import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';

import theresaImg from '../../images/theresa.jpg';
import newelImg from '../../images/newel.jpg';
import michaelImg from '../../images/michael.jpg';
import dannyImg from '../../images/danny.jpg';

import leftArrow from '../../images/arrow-left-circle-carousel.svg';
import rightArrow from '../../images/arrow-right-circle-carousel.svg';

const TeamCarousel: React.FC = () => {
	const teamMembers = [
		{
			name: 'Theresa',
			title: 'Bus Driver',
			image: theresaImg,
			text: '“We’re all like a big family. We have barbeques and kid’s birthday parties! We even have a Facebook page where we keep in touch. It’s a great group of people.”'
		},
		{
			name: 'Newel',
			title: 'VP of Operations',
			image: newelImg,
			text: '“There’s a wealth of opportunity and growth at Coach USA for people of all walks of life and in the community.”'
		},
		{
			name: 'Michael',
			title: 'Mechanic',
			image: michaelImg,
			text: '“A successful person at Coach USA is someone who takes pride in their work and cares about their work. Willing to listen to criticism and take full understanding of their job function.”'
		},
		{
			name: 'Danny',
			title: 'Dispatcher',
			image: dannyImg,
			text: '“I came to Coach USA, to further my career and have stabilization. I get to take things apart and making it work again. I enjoy interacting with people who know more than I do and that I can learn from. You can always learn more.  We all help each other and teach one another how to do things the right way.”'
		}
	];

	SwiperCore.use([Autoplay, Pagination, Navigation]);
	return (
		<section className={'bg-white px-1 py-16 text-center'}>
			<h2 className={'text-4xl font-normal'}>The Team</h2>
			<div className={'flex items-center justify-center'}>
				<div className="custom-button-prev w-[15vw] cursor-pointer pr-3">
					<img src={leftArrow} alt="previous" className="mx-auto" />
				</div>

				<Swiper
					navigation={{
						prevEl: '.custom-button-prev',
						nextEl: '.custom-button-next'
					}}
					modules={[Navigation, Pagination]}
					spaceBetween={30}
					loop
					pagination={{ clickable: true }}
					autoplay={{ delay: 3000 }}
					slidesPerView={1}
					centerInsufficientSlides
					centeredSlides
					centeredSlidesBounds
					className="w-[80vw]"
				>
					{teamMembers.map((member, index) => {
						return (
							<SwiperSlide key={index + member.title}>
								<div
									className={
										'flex h-[580px] flex-col items-center justify-center gap-10 md:h-[650px] md:px-10 lg:py-10'
									}
								>
									<div
										className={
											'flex flex-col items-center justify-center gap-10 text-left'
										}
									>
										<p
											className={
												'h-2/3 max-w-2xl text-center text-base lg:text-2xl'
											}
											dangerouslySetInnerHTML={{
												__html: member.text
											}}
										></p>
										<div>
											<p
												className={
													'text-center text-xl font-normal md:text-3xl'
												}
											>
												{member.name}
											</p>
											<p
												className={
													'text-center text-xl font-normal'
												}
											>
												{member.title}
											</p>
										</div>
									</div>
									<img
										className={'mx-auto md:max-w-[350px]'}
										src={member.image}
										alt={member.title}
									/>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>

				<div className="custom-button-next w-[15vw] cursor-pointer pl-3">
					<img src={rightArrow} alt="next" className="mx-auto" />
				</div>
			</div>
		</section>
	);
};

export default TeamCarousel;
