import * as React from 'react';

import MainButton from '../MainButton/MainButton';

interface TestimonialVideosProps {
	testimonials: Array<{
		videoURL: string;
		name: string;
		position: string;
		buttonText: string;
		buttonLink: string;
	}>;
}

// @ts-ignore
import arrow from '../../images/arrow-right-circle-carousel.svg';

const TestimonialVideos: React.FC<TestimonialVideosProps> = (
	props: TestimonialVideosProps
) => {
	return (
		<section className={'bg-[#F2F9FE]'}>
			{props.testimonials.map((test, index) => {
				return (
					<div
						className={'flex flex-col items-center justify-center'}
					>
						<div
							className={[
								'flex w-full max-md:flex-col',
								!(index % 2) ? 'flex-row' : 'flex-row-reverse'
							].join(' ')}
						>
							<div
								className={
									'text-bt-gray flex w-1/2 flex-col items-center justify-center p-3 max-md:w-full max-md:items-start'
								}
							>
								<div
									className={
										'flex flex-col gap-6 max-md:py-8'
									}
								>
									<h3
										className={
											'text-3xl font-bold text-primary'
										}
									>
										Meet {test.name}
									</h3>
									<p className={'text-xl font-bold'}>
										{test.position}
									</p>
									<a href={test.buttonLink}>
										<MainButton>
											{test.buttonText}
										</MainButton>
									</a>
								</div>
							</div>
							<div
								className={'flex w-1/2 flex-col max-md:w-full'}
							>
								<iframe
									className={'aspect-video w-full'}
									src={test.videoURL}
									title="YouTube video player"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowFullScreen
								/>
							</div>
						</div>
					</div>
				);
			})}
		</section>
	);
};

export default TestimonialVideos;
