import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';

import individual1 from '../../images/team-1.png';
import individual2 from '../../images/team-2.png';
import individual3 from '../../images/team-3.png';
import individual4 from '../../images/team-4.jpg';
import individual5 from '../../images/team-5.jpg';

import leftArrow from '../../images/arrow-left-circle-carousel.svg';
import rightArrow from '../../images/arrow-right-circle-carousel.svg';

const IndividualsCarousel: React.FC = () => {
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);

	const handleSlideChange = (swiper: SwiperCore) => {
		setActiveSlideIndex(swiper.realIndex);
		console.log(swiper);
	};

	const individuals = [
		{
			id: 0,
			image: individual1
		},
		{
			id: 1,
			image: individual2
		},
		{
			id: 2,
			image: individual3
		},
		{
			id: 3,
			image: individual4
		},
		{
			id: 4,
			image: individual5
		}
	];

	SwiperCore.use([Autoplay, Pagination, Navigation]);

	return (
		<section className={'bg-white px-1 py-16 text-center'}>
			<div className="container mx-auto flex flex-col gap-6 px-4 py-6">
				<h2 className={'text-4xl font-normal'}>Going the Distance</h2>
				<p className={'text-lg font-normal'}>
					Meet some of the amazing individuals whose positive work
					ethic caught our attention.
				</p>
			</div>
			<div className={'flex items-center justify-center'}>
				<div className="custom-button-prev w-[15vw] cursor-pointer pr-3">
					<img src={leftArrow} alt="previous" className="mx-auto" />
				</div>
				<Swiper
					onSlideChange={handleSlideChange}
					navigation={{
						prevEl: '.custom-button-prev',
						nextEl: '.custom-button-next'
					}}
					modules={[Navigation, Pagination, Autoplay]}
					spaceBetween={15}
					loop
					autoplay={{ delay: 3000 }}
					slidesPerView={2}
					centerInsufficientSlides
					centeredSlides
					centeredSlidesBounds
					className="w-[80vw]"
				>
					{individuals.map((individual, index) => {
						return (
							<SwiperSlide key={index + individual.id}>
								<img
									src={individual.image}
									alt="Individual"
									className={
										'mx-auto ' +
										(activeSlideIndex === index
											? ' opacity-100'
											: ' opacity-50')
									}
								/>
							</SwiperSlide>
						);
					})}
				</Swiper>

				<div className="custom-button-next w-[15vw] cursor-pointer pl-3">
					<img src={rightArrow} alt="next" className="mx-auto" />
				</div>
			</div>
		</section>
	);
};

export default IndividualsCarousel;
