import React from 'react';
import { PageProps, graphql, useStaticQuery } from 'gatsby';

import Layout from '../../layouts/Layout';
import JobSearchForm from '../../components/JobSearchForm/JobSearchForm';
import TeamCarousel from '../../components/TeamCarousel/TeamCarousel';
import TestimonialVideos from '../../components/TestimonialVideos/TestimonialVideos';
import IndividualsCarousel from '../../components/IndividualsCarousel/IndividualsCarousel';
import ReadyStartSection from '../../components/ReadyStartSection/ReadyStartSection';

import menuItems from '../../constants/menuItems';
import heroTeamImg from '../../images/team-hero.png';

const testimonials = [
	{
		videoURL: 'https://www.youtube.com/embed/u1qc3K_Vz-A',
		name: 'Theresa Najdzion',
		position: 'Driver',
		buttonText: 'Search Driver Careers',
		buttonLink: '/jobs/?term=Driver'
	},
	{
		videoURL: 'https://www.youtube.com/embed/0znyXKgwuik',
		name: 'Danny Batts',
		position: 'Dispatcher',
		buttonText: 'Search Dispatcher Careers',
		buttonLink: '/jobs/?term=Dispatcher'
	},
	{
		videoURL: 'https://www.youtube.com/embed/g3WvrpqsAOc',
		name: 'Michael Conway',
		position: 'Mechanic',
		buttonText: 'Search Mechanic Careers',
		buttonLink: '/jobs/?term=Mechanic'
	}
];

const IndexPage = (props: PageProps) => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	return (
		<Layout
			location={props.location}
			menuItems={menuItems}
			title={'Coach USA Careers'}
			desc={'Coach USA Careers'}
		>
			<JobSearchForm
				data={data}
				alignLeft
				imgSrc={heroTeamImg}
				h2="Join our transportation team and drive your career to new places!"
			></JobSearchForm>
			<TeamCarousel />
			<TestimonialVideos testimonials={testimonials} />
			<IndividualsCarousel></IndividualsCarousel>
			<ReadyStartSection />
		</Layout>
	);
};

export default IndexPage;
